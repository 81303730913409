import {useBackgroundStyleConfigs} from 'hooks/useBackgroundStyleConfigs';
import Image from 'next/image';
import React, {FC} from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {TypoComponentsAccordionSection} from 'lib/api/strapi';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from 'components/basic-components';
import MarkdownContent from 'components/basic-components/MarkdownContent';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import styles from './AccordionSection.module.scss';

const AccordionSection: FC<TypoComponentsAccordionSection> = ({
  accordionTitle,
  textSection1,
  textSection2,
  title,
  image,
  text,
  titleLevel,
}) => {
  const {
    accordion: {backgroundColor, textColor},
  } = useBackgroundStyleConfigs();
  return (
    <Box sx={{padding: '50px 0'}}>
      <Accordion variant="base" sx={{backgroundColor, color: textColor}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color: textColor, width: '24px', height: '24px'}} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Box>
            <Typography
              sx={{lineHeight: '30px'}}
              component={titleLevel ? titleLevel : 'span'}
              variant="footerCards"
            >
              {accordionTitle}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {textSection1 && textSection2 ? (
            <Grid container columnSpacing={{xxs: 0, md: '2%'}} sx={{rowGap: '2rem'}}>
              <Grid xxs={12} md={6}>
                <MarkdownContent className={styles.richText} content={textSection1} />
              </Grid>
              <Grid xxs={12} md={6}>
                <MarkdownContent className={styles.richText} content={textSection2} />
              </Grid>
            </Grid>
          ) : (
            <Grid container columnSpacing={'2%'}>
              {image?.data?.attributes?.url ? (
                <Grid xxs={12} md={6}>
                  <Image
                    className={styles.image}
                    src={image?.data?.attributes?.url}
                    alt=""
                    width={image?.data?.attributes?.width}
                    height={image?.data?.attributes?.height}
                  />
                </Grid>
              ) : null}
              <Grid xxs={12} md={image?.data?.attributes?.url ? 6 : 12}>
                {title ? (
                  <Typography
                    component={'h3'}
                    className={styles.title}
                    sx={{
                      typography: {xxs: 'teaser', lg: 'h1'},
                    }}
                  >
                    {title}
                  </Typography>
                ) : null}
                <MarkdownContent className={styles.richText} content={text} />
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default withErrorBoundary(AccordionSection, ' AccordionSection');
